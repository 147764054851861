import React, { useState, useEffect, useCallback, useRef } from 'react';
import qs from 'qs';
// import { StyleSheet, ImageBackground, TouchableOpacity } from 'react-native';
import { VStack, HStack, Center, Text, Input, Icon, CheckIcon, Select, Button } from 'native-base';
import { MaterialIcons } from '@expo/vector-icons';
import { AppProps, PlayerFilters, PlayerSortField, ApiSortDirection, PlayerScreenProps, RootTabScreenProps } from '../types';

export default function PlayerSearch(props: PlayerScreenProps<PlayerFilters>) {
  const { data, query, prevPage, nextPage, updateQuery } = props;
  const [ search, setSearch ] = useState<string>(query?.filter?.search || '');
  const SearchIcon = () => <Icon m="2" ml="3" size="6" color="gray.400" as={<MaterialIcons name="search" />} />;

  const searchValue = useCallback((value: string) => {
    updateQuery({ page: { number: 1 }, filter: { ...query.filter, search: value } });
  }, [query]);

  const doSearch = useCallback(() => {
    searchValue(search);
  }, [search]);

  const onKeyPress = (e: any) => {
    if (e?.key === 'Enter') {
      doSearch();
    }
  };

  return (
    <Center p={2} width="100%" maxW={'500'}>
      <Input
        width={'full'}
        variant={'underlined'}
        placeholder={'Search Players'}
        value={search}
        onChangeText={(val) => setSearch(val)}
        InputLeftElement={<SearchIcon />}
        onKeyPress={onKeyPress}
        onBlur={doSearch}
      />
    </Center>
  );
}
