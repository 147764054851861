import React, { useEffect, useState, useCallback } from 'react';
import qs from 'qs';
import moment from 'moment';
import { SafeAreaView } from 'react-native';
import { VStack, HStack, Heading, Text, View, ScrollView, Button, Spinner, Box, Image, AspectRatio } from 'native-base';
import { useBreakpointValue } from 'native-base';
import { useAxios } from '../hooks';
import { ApiGameList, GameData, AppProps, GameFilters, GameScreenProps } from '../types';
import GameBox from './GameBox';

export default function GameResults(props: GameScreenProps<GameFilters>) {
  const { query, data, error, loaded, prevPage, nextPage } = props;
  const [ gameData, setGameData ] = useState<GameData[]>([]);

  const hasFilters = (query.filter?.search || query.filter?.winner || query.filter?.started_at__gte || query.filter?.game_type || query.filter?.box__os__iexact || query.filter?.box__title__icontains);

  const getSubject = (): string => {
    let prefix = 'all';
    let subject = 'games';

    if (hasFilters) {
      prefix = 'searching for';
    }

    if (query.filter?.box__os__iexact) {
      subject = `${query.filter.box__os__iexact} ${subject}`
    }

    if (query.filter?.game_type) {
      subject = `${query.filter.game_type} ${subject}`
    }

    return `${prefix} ${subject}`;
  };

  const Summary = () => {
    return (
      <Box mx={3} minW={'300'}>
        <VStack>
          <Box p={2} backgroundColor={'amber.100'}>
            <Heading size={'xs'} textAlign={'center'}>
              {getSubject()}
            </Heading>
            {query.filter?.search && (
              <Text textAlign={'center'}>
                with "{query.filter.search}"
              </Text>
            )}
            {query.filter?.started_at__gte && (
              <Text textAlign={'center'}>
                since {moment(query.filter.started_at__gte).fromNow()}
              </Text>
            )}
            {query.filter?.winner && (
              <Text textAlign={'center'}>
                won by "{query.filter.winner}"
              </Text>
            )}
            {query.filter?.players__username__icontains && (
              <Text textAlign={'center'}>
                players "{query.filter.players__username__icontains}"
              </Text>
            )}
            {query.filter?.box__title__icontains && (
              <Text textAlign={'center'}>
                on box "{query.filter.box__title__icontains}"
              </Text>
            )}
            <Text textAlign={'center'} color={'gray.600'}>
              found {data?.meta?.pagination?.count} games
            </Text>
          </Box>
        </VStack>
      </Box>
    );
  };

  const Pagination = () => {
    if (!data?.meta?.pagination) {
      return <></>;
    }

    return (
      <HStack m={1} alignItems='center' minW={'300'} justifyContent='space-between'>
        <Button size={'sm'} colorScheme={'info'} isDisabled={!!data?.meta?.pagination?.page && data?.meta?.pagination?.page <= 1} onPress={prevPage}>
          prev
        </Button>
        <Text fontSize={'sm'} color='muted.400'>Page {data?.meta?.pagination?.page} of {data?.meta?.pagination?.pages}</Text>
        <Button size={'sm'} colorScheme={'info'} isDisabled={data?.meta?.pagination?.page == data?.meta?.pagination?.pages} onPress={nextPage}>
          next
        </Button>
      </HStack>
    );
  };

  useEffect(() => {
    let results: GameData[] = [];

    if (data?.data?.length) {
      results = data.data.map(row => row.attributes);
    }

    setGameData(results);
  }, [ data ]);

  if (loaded) {
    return error ? (
      <Heading>Error: {error}</Heading>
    ) : (
      <ScrollView showsVerticalScrollIndicator={true} width={'full'}>
        <SafeAreaView>
          <VStack py="4" space={4} alignItems="center" width={'full'} maxW={600} alignSelf="center" justifyContent="center">
            <Summary />
            {gameData.length > 0 ? (
              <>
                <Pagination />
                {gameData.map((game, i) => (
                  <GameBox key={i} game={game} />
                ))}
                <Pagination />
              </>
            ) : (
              <Box width={'full'} maxW={'420'}>
                <AspectRatio w="100%" ratio={5/4}>
                  <Image source={{
                    uri: 'https://tryhackme-images.s3.amazonaws.com/user-avatars/35b76f799bc2d877a4bfaf0635fd5826.png'
                  }} alt={'Oops no games found'} />
                </AspectRatio>
              </Box>
            )}
          </VStack>
        </SafeAreaView>
      </ScrollView>
    );
  }

  return (
    <HStack mt={10} space={2} justifyContent="center">
      <Spinner accessibilityLabel="Loading games" />
      <Heading color="primary.500" fontSize="lg">
        loading games
      </Heading>
    </HStack>
  );
}
