import moment from 'moment';
import { ApiQuery, GameFilters, PlayerFilters } from '../types';

export const DefaultPageSize = 24;
export const DefaultGameFilters: GameFilters = {
  boxed: true,
  status: 'complete',
  gameplayers__score__gt: 0
};

export const DefaultGameQuery: ApiQuery<GameFilters> = {
  sort: '-started_at',
  page: { number: 1, size: DefaultPageSize },
  filter: DefaultGameFilters,
}

export const DefaultPlayerFilters: PlayerFilters = {
  // played_at_least: '2',
  // played_after: moment().subtract(30, 'days').toDate()
};

export const DefaultPlayerQuery: ApiQuery<PlayerFilters> = {
  sort: '-total_score',
  page: { number: 1, size: 24 },
  filter: DefaultPlayerFilters
};