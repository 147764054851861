import React, { useState, useCallback } from 'react';
import { Input } from 'native-base';

export interface KothNumberInputProps {
  name: string;
  width?: string;
  placeholder?: string;
  defaultValue?: string;
  onUpdated?: (val: string) => void;
}

export default function KothNumberInput(props: KothNumberInputProps) {
  const { name, width, placeholder, defaultValue, onUpdated } = props;
  const [ value, setValue ] = useState<string>(defaultValue || '');

  const handleUpdate = useCallback(() => {
    if (typeof onUpdated === 'function') {
      onUpdated(value);
    }
  }, [value]);

  const onKeyPress = (e: any) => {
    if (e?.key === 'Enter') {
      handleUpdate();
    }
  };

  return (
    <Input
      key={name}
      width={width}
      placeholder={placeholder}
      keyboardType='numeric'
      value={value}
      onChangeText={(val) => setValue(val)}
      onKeyPress={onKeyPress}
      onBlur={handleUpdate}
    />
  );
}
