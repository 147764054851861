import React, { useState, useEffect, useCallback } from 'react';
import qs from 'qs';
import moment from 'moment';
import { Platform, SafeAreaView } from 'react-native';
import { Heading, CheckIcon, Center, ScrollView, VStack, HStack, FormControl, Select, Input, Switch, Button } from 'native-base';
import { GameFilters, GameSortField, ApiSortDirection, ApiQuery, RootStackScreenProps } from '../types';
import { DefaultGameFilters, DefaultGameQuery } from '../constants';
import KothInput from '../components/KothInput';

// need to abstract this component so it's reusable
export const SearchGamesScreen = (props: RootStackScreenProps<'SearchGames'>) => {
  const [ filters, setFilters ] = useState<GameFilters>(DefaultGameFilters);
  const [ errors, setErrors ] = useState<GameFilters>({});
  const [ sortField, setSortField ] = useState<GameSortField>('started_at')
  const [ sortDirection, setSortDirection ] = useState<ApiSortDirection>('desc');

  const validate = useCallback((data: GameFilters) => {
    if (data.winner && data.winner.length < 2) {
      setErrors({ ...errors,
        winner: 'Name is too short'
      });
      return false;
    }

    return true;
  }, [errors]);

  const reset = () => {
    setFilters(DefaultGameFilters);

    props.navigation.push('Root', { screen: 'Games', params: {} });
  };

  const submit = useCallback(() => {
    if (validate(filters)) {
      const prefix = sortDirection === 'desc' ? '-' : '';
      const sort = `${prefix}${sortField}`;

      props.navigation.push('Root', { screen: 'Games', params: {
        api: qs.stringify({ ...DefaultGameQuery, sort, filter: filters })
      }});
    }
  }, [ filters, sortField, sortDirection ]);

  return (
    <Center
      _dark={{ bg: "blueGray.900" }}
      _light={{ bg: "blueGray.50" }}
      px={4}
      flex={1}
    >
      <SafeAreaView>
        <ScrollView showsVerticalScrollIndicator={true} width='full'>
          <VStack p={5} space={2} alignSelf="center" w={'80%'} minW={280} maxW={420}>
            <FormControl mt={2}>
              <FormControl.Label _text={{
                bold: true
              }}>Date Range</FormControl.Label>
                <Select
                  mt={1}
                  selectedValue={filters?.started_at__gte ? moment().diff(moment(filters?.started_at__gte), 'days').toString() : ''}
                  onValueChange={(val) => setFilters({ ...filters, started_at__gte: moment().subtract(val, 'days').toDate() })}
                  accessibilityLabel="Date Range"
                  placeholder="Date Range"
                  _selectedItem={{
                    bg: "teal.600",
                    endIcon: <CheckIcon size="5" />
                  }}
                >
                  <Select.Item label="1 Day" value={'1'} />
                  <Select.Item label="7 Days" value={'7'} />
                  <Select.Item label="30 Days" value={'30'} />
                  <Select.Item label="60 Days" value={'60'} />
                  <Select.Item label="90 Days" value={'90'} />
                  <Select.Item label="180 Days" value={'180'} />
                  <Select.Item label="360 Days" value={'360'} />
                  <Select.Item label="720 Days" value={'720'} />
                </Select>
            </FormControl>
            <FormControl isInvalid={'winner' in errors}>
              <FormControl.Label _text={{
                bold: true
              }}>Winner</FormControl.Label>
              <KothInput name='winner' placeholder='F11' defaultValue={filters.winner} onUpdated={(winner) => setFilters({ ...filters, winner })} />
              {'winner' in errors && (
                <FormControl.ErrorMessage _text={{
                  fontSize: 'xs'
                }}>
                  {errors.winner}
                </FormControl.ErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={'players__username__icontains' in errors}>
              <FormControl.Label _text={{
                bold: true
              }}>Player</FormControl.Label>
              <KothInput name='players__username__icontains' placeholder='snipe' defaultValue={filters.players__username__icontains} onUpdated={(players__username__icontains) => setFilters({ ...filters, players__username__icontains })} />
              {'players__username__icontains' in errors && (
                <FormControl.ErrorMessage _text={{
                  fontSize: 'xs'
                }}>
                  {errors.players__username__icontains}
                </FormControl.ErrorMessage>
              )}
            </FormControl>
            <FormControl>
              <FormControl.Label _text={{
                bold: true
              }}>Game Type</FormControl.Label>
                <Select onValueChange={(val) => setFilters({ ...filters, game_type: val })} selectedValue={filters.game_type || ''} accessibilityLabel="Game Type" placeholder="All Games" _selectedItem={{
                  bg: "teal.600",
                  endIcon: <CheckIcon size="5" />
                }} mt={1}>
                  <Select.Item key='public' label="Public" value={'public'} />
                  <Select.Item key='private' label="Private" value={'private'} />
                </Select>
            </FormControl>
            <FormControl>
              <FormControl.Label _text={{
                bold: true
              }}>Box OS</FormControl.Label>
                <Select onValueChange={(val) => setFilters({ ...filters, box__os__iexact: val })} selectedValue={filters?.box__os__iexact || ''} accessibilityLabel="Box Operating System" placeholder="Any OS" _selectedItem={{
                  bg: "teal.600",
                  endIcon: <CheckIcon size="5" />
                }} mt={1}>
                  <Select.Item label="Linux" value={'linux'} />
                  <Select.Item label="Windows" value={'windows'} />
                </Select>
            </FormControl>
            <FormControl isInvalid={'box__title' in errors}>
              <FormControl.Label _text={{
                bold: true
              }}>Box Title</FormControl.Label>
              <KothInput name='box__title__icontains' placeholder='Search box titles' defaultValue={filters.box__title__icontains} onUpdated={(val) => setFilters({ ...filters, box__title__icontains: val })} />
              {'box__title__icontains' in errors && (
                <FormControl.ErrorMessage _text={{
                  fontSize: 'xs'
                }}>
                  {errors.box__title__icontains}
                </FormControl.ErrorMessage>
              )}
            </FormControl>
            <FormControl>
              <FormControl.Label _text={{
                bold: true
              }}>Sort By</FormControl.Label>
                <Select onValueChange={(val) => setSortField(val as GameSortField)} selectedValue={sortField} accessibilityLabel="Sort Games By" placeholder="Sort By" _selectedItem={{
                  bg: "teal.600",
                  endIcon: <CheckIcon size="5" />
                }} mt={1}>
                  <Select.Item label="Started At" value={'started_at'} />
                  <Select.Item label="King Changes" value={'king_changes'} />
                  <Select.Item label="Resets" value={'resets'} />
                </Select>
            </FormControl>
            <FormControl>
              <FormControl.Label _text={{
                bold: true
              }}>Sort Direction</FormControl.Label>
              <Select minW='35%' selectedValue={sortDirection} accessibilityLabel="Sort Direction" placeholder="Sort Direction" _selectedItem={{
                bg: "teal.600",
                endIcon: <CheckIcon size="5" />
              }} mt={1} onValueChange={itemValue => setSortDirection(itemValue as ApiSortDirection)}>
                <Select.Item label="Ascending" value={'asc'} />
                <Select.Item label="Descending" value={'desc'} />
              </Select>
            </FormControl>
            <Button.Group mt={5} space={2}>
              <Button colorScheme="danger" onPress={() => {
                reset();
              }}>
                Reset
              </Button>
              <Button onPress={() => {
                submit();
              }}>
                Search
              </Button>
            </Button.Group>
          </VStack>
        </ScrollView>
      </SafeAreaView>
    </Center>
  );
}

export default SearchGamesScreen;
