import React, { useEffect, useState } from 'react';
import qs from 'qs';
import moment from 'moment';
import { Box, VStack, HStack, Heading, Text, View, ScrollView, Button, Spinner, AspectRatio, Image } from 'native-base';
import { useBreakpointValue } from 'native-base';
import { useAxios } from '../hooks';
import { ApiPlayerList, PlayerData, AppProps, PlayerFilters, PlayerScreenProps } from '../types';
import PlayerRow from './PlayerRow';

export default function PlayerResults(props: PlayerScreenProps<PlayerFilters>) {
  const { query, data, error, loaded, prevPage, nextPage } = props;
  const [ playerData, setPlayerData ] = useState<PlayerData[][]>([]);

  const flexCols = useBreakpointValue({
    base: 1,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 6
  });

  const hasFilters = (query.filter?.search || query.filter?.played_after || query.filter?.played_at_least || query.filter?.played_at_most);

  const getSubject = (): string => {
    let prefix = 'all';
    let subject = 'players';

    if (hasFilters) {
      prefix = 'searching for';
    }

    return `${prefix} ${subject}`;
  };

  const Summary = () => {
    return (
      <Box mx={3} minW={'300'}>
        <VStack>
          <Box p={2} backgroundColor={'amber.100'}>
            <Heading size={'xs'} textAlign={'center'}>
              {getSubject()}
            </Heading>
            {query.filter?.search && (
              <Text textAlign={'center'}>
                with "{query.filter.search}"
              </Text>
            )}
            {query.filter?.played_after && (
              <Text textAlign={'center'}>
                since {moment(query.filter.played_after).fromNow()}
              </Text>
            )}
            {query.filter?.played_at_least && (
              <Text textAlign={'center'}>
                at least {query.filter.played_at_least} games
              </Text>
            )}
            {query.filter?.played_at_most && (
              <Text textAlign={'center'}>
                at most {query.filter.played_at_most} games
              </Text>
            )}
            <Text textAlign={'center'} color={'gray.600'}>
              found {data?.meta?.pagination?.count} players
            </Text>
          </Box>
        </VStack>
      </Box>
    );
  };

  const Pagination = () => {
    if (!data?.meta?.pagination) {
      return <></>;
    }

    return (
      <HStack m={1} alignItems='center' minW={'300'} justifyContent='space-between'>
        <Button size={'sm'} colorScheme={'info'} isDisabled={!!data?.meta?.pagination?.page && data?.meta?.pagination?.page <= 1} onPress={prevPage}>
          prev
        </Button>
        <Text fontSize={'sm'} color='muted.400'>Page {data?.meta?.pagination?.page} of {data?.meta?.pagination?.pages}</Text>
        <Button size={'sm'} colorScheme={'info'} isDisabled={data?.meta?.pagination?.page == data?.meta?.pagination?.pages} onPress={nextPage}>
          next
        </Button>
      </HStack>
    );
  };

  useEffect(() => {
    const results: PlayerData[][] = [];

    if (data?.data?.length) {
      for (let i = 0; i < data?.data.length; i+=flexCols) {
        const group: PlayerData[] = [];

        for (let j = i; j < i+flexCols; j++) {
          if (data.data[j]) {
            group.push(data.data[j].attributes);
          } else {
            console.warn(`Missing group player: [${i}][${j}]`);
          }
        }

        results.push(group);
      }
    }

    setPlayerData(results);
  }, [ data, flexCols ]);

  if (loaded) {
    return error ? (
      <Heading>Error: {error}</Heading>
    ) : (
      <ScrollView width={'full'} showsVerticalScrollIndicator={true}>
        <VStack py="4" space={4} alignItems="center" justifyContent="center">
          <Summary />
          {playerData.length > 0 ? (
            <>
              <Pagination />
              {playerData.map((players, i) => (
                <PlayerRow key={i} players={players} />
              ))}
              <Pagination />
            </>
          ) : (
            <Box width={'full'} maxW={'420'}>
              <AspectRatio w="100%" ratio={5/4}>
                <Image source={{
                  uri: 'https://tryhackme-images.s3.amazonaws.com/user-avatars/35b76f799bc2d877a4bfaf0635fd5826.png'
                }} alt={'Oops no games found'} />
              </AspectRatio>
            </Box>
          )}
        </VStack>
      </ScrollView>
    );
  }

  return (
    <HStack mt={10} space={2} justifyContent="center">
      <Spinner accessibilityLabel="Loading players" />
      <Heading color="primary.500" fontSize="lg">
        loading players
      </Heading>
    </HStack>
  );
}
