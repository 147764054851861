import React, { useState, useEffect, useCallback } from "react";
import qs from 'qs';
import moment from 'moment';
import { Center, extendTheme, useBreakpointValue } from "native-base";
import PlayerSearch from "../components/PlayerSearch";
import PlayerControls from "../components/PlayerControls";
import PlayerResults from "../components/PlayerResults";
import { PlayerDataField, PlayerData, ApiPlayerList, ApiQuery, PlayerFilters, PlayersRouteProps, RootTabScreenProps } from '../types';
import { DefaultPlayerQuery } from '../constants';
import { useAxios } from '../hooks';


// Define the config
const config = {
  useSystemColorMode: false,
  initialColorMode: "dark",
};

// extend the theme
export const theme = extendTheme({ config });
type MyThemeType = typeof theme;
declare module "native-base" {
  interface ICustomTheme extends MyThemeType {}
}

export const PlayerStatsScreen = (props: RootTabScreenProps<'Players'>) => {
  const { route } = props;
  const [ query, setQuery ] = useState<ApiQuery<PlayerFilters>>(route.params?.api ? qs.parse(route.params.api) : DefaultPlayerQuery);

  const { data, error, loaded } = useAxios<ApiPlayerList>(`players?${qs.stringify(query)}`);

  const updateQuery = useCallback((newQuery: ApiQuery<PlayerFilters>) => {
    props.navigation.push('Root', { screen: 'Players', params: {
      api: qs.stringify({...query, ...newQuery})
    }});
  }, [query]);

  const setPage = useCallback((page: number) => {
    updateQuery({ page: {...query.page, number: page} });
  }, [query]);

  const currentPage = typeof query.page?.number === 'string' ? parseInt(query.page.number) : query.page?.number;
  const prevPage = () => setPage(currentPage ? currentPage - 1 : 1);
  const nextPage = () => setPage(currentPage ? currentPage + 1 : 1);

  useEffect(() => {
    setQuery(route.params?.api ? qs.parse(route.params.api) : DefaultPlayerQuery);
  }, [route]);

  // useEffect(() => {
  //   // props.navigation.navigate('Players', { api: qs.stringify(query) });
  //   props.navigation.push('Players', { api: qs.stringify(query) });
  // }, [query]);

  const appProps = {
    ...props,
    data,
    error,
    loaded,
    query,
    setPage,
    prevPage,
    nextPage,
    setQuery,
    updateQuery,
  };

  return (
    <Center
      _dark={{ bg: "blueGray.900" }}
      _light={{ bg: "blueGray.50" }}
      px={4}
      flex={1}
    >
      {loaded && <PlayerSearch {...appProps} />}
      <PlayerResults {...appProps} />
    </Center>
  );
}
