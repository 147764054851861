import React, { useState, useCallback } from 'react';
import { Center, Input, Icon } from 'native-base';
import { MaterialIcons } from '@expo/vector-icons';
import { GameFilters, GameScreenProps } from '../types';

export default function GameSearch(props: GameScreenProps<GameFilters>) {
  const { query, updateQuery } = props;
  const [ search, setSearch ] = useState<string>(query?.filter?.search || '');
  const SearchIcon = () => <Icon m="2" ml="3" size="6" color="gray.400" as={<MaterialIcons name="search" />} />;

  const searchValue = useCallback((value: string) => {
    updateQuery({ page: { number: 1 }, filter: { ...query.filter, search: value } });
  }, [query]);

  const doSearch = useCallback(() => {
    searchValue(search);
  }, [search]);

  const onKeyPress = (e: any) => {
    if (e?.key === 'Enter') {
      doSearch();
    }
  };

  return (
    <Center p={2} width="100%" maxW={'500'}>
      <Input
        width={'full'}
        variant={'underlined'}
        placeholder={'Search Games'}
        value={search}
        onChangeText={(val) => setSearch(val)}
        InputLeftElement={<SearchIcon />}
        onKeyPress={onKeyPress}
        onBlur={doSearch}
      />
    </Center>
  );
}
