import React, { useState, useEffect, useCallback } from "react";
import qs from 'qs';
import moment from "moment";
import { Center, extendTheme, Text } from "native-base";
import GameSearch from "../components/GameSearch";
import GameResults from "../components/GameResults";
import { ApiQuery, GameFilters, ApiGameList, GamesRouteProps, RootTabScreenProps } from '../types';
import { useAxios } from '../hooks';
import { DefaultGameFilters, DefaultGameQuery } from '../constants';

export const GameStatsScreen = (props: RootTabScreenProps<'Games'>) => {
  const { route } = props;
  const [ query, setQuery ] = useState<ApiQuery<GameFilters>>(route.params?.api ? qs.parse(route.params.api) : DefaultGameQuery);

  const resetQuery = () => {
    props.navigation.push('Root', { screen: 'Games', params: {
      api: qs.stringify(DefaultGameQuery)
    }});
  };

  const updateQuery = useCallback((newQuery: ApiQuery<GameFilters>) => {
    props.navigation.push('Root', { screen: 'Games', params: {
      api: qs.stringify({...query, ...newQuery})
    }});
  }, [query]);


  const { data, error, loaded } = useAxios<ApiGameList>(`games?${qs.stringify(query)}`);

  const setPage = useCallback((page: number) => {
    updateQuery({ page: {...query.page, number: page} });
  }, [query]);

  const currentPage = typeof query.page?.number === 'string' ? parseInt(query.page.number) : query.page?.number;
  const prevPage = () => setPage(currentPage ? currentPage - 1 : 1);
  const nextPage = () => setPage(currentPage ? currentPage + 1 : 1);

  useEffect(() => {
    setQuery(route.params?.api ? qs.parse(route.params.api) : DefaultGameQuery);
    // alert('query api params effect: ' + JSON.stringify(route.params?.api));
  }, [route]);

  // const setPage = useCallback((page: number) => {
  //   setQuery({...query, page: {...query.page, number: page}});
  // }, [query]);

  // const prevPage = () => setPage(query.page?.number ? query.page?.number - 1 : 1);
  // const nextPage = () => setPage(query.page?.number ? query.page?.number + 1 : 1);

  // useEffect(() => {
  //   props.navigation.navigate('Games', { api: qs.stringify(query) });
  // }, [query]);

  const appProps = {
    ...props,
    data,
    error,
    loaded,
    query,
    setPage,
    prevPage,
    nextPage,
    setQuery,
    resetQuery,
    updateQuery,
  };

  return (
    <Center
      _dark={{ bg: "blueGray.900" }}
      _light={{ bg: "blueGray.50" }}
      px={4}
      flex={1}
    >
      {loaded && <GameSearch {...appProps} />}
      <GameResults {...appProps} />
    </Center>
  );
}
