import React, { useState, useEffect, useCallback } from 'react';
import qs from 'qs';
import moment from 'moment';
import { Platform, SafeAreaView, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import { Heading, CheckIcon, Center, ScrollView, VStack, HStack, FormControl, Select, Input, Switch, Button } from 'native-base';
import { PlayerFilters, PlayerSortField, ApiSortDirection, ApiQuery, RootStackScreenProps } from '../types';
import { DefaultPlayerFilters, DefaultPlayerQuery } from '../constants';
import KothInput from '../components/KothInput';
import KothNumberInput from '../components/KothNumberInput';

// need to abstract this component so it's reusable
export const SearchPlayersScreen = (props: RootStackScreenProps<'SearchPlayers'>) => {
  const [ filters, setFilters ] = useState<PlayerFilters>(DefaultPlayerFilters);
  const [ errors, setErrors ] = useState<PlayerFilters>({});
  const [ sortField, setSortField ] = useState<PlayerSortField>('total_score')
  const [ sortDirection, setSortDirection ] = useState<ApiSortDirection>('desc');

  const validate = (data: PlayerFilters) => {
    let valid = true;
    let newErrors: PlayerFilters = {};

    setErrors({});

    if (typeof data.played_at_least !== 'undefined' && !/^[0-9]+$/.test(data.played_at_least)) {
      newErrors.played_at_least = 'Must be number greater than 0';
      valid = false;
    }

    if (typeof data.played_at_most !== 'undefined' && !/^[0-9]+$/.test(data.played_at_most)) {
      newErrors.played_at_most = 'Must be number greater than 0';
      valid = false;
    } else if (data.played_at_most && data.played_at_least && !newErrors.played_at_least) {
      if (parseInt(data.played_at_most) <= parseInt(data.played_at_least)) {
        newErrors.played_at_most = `Must be greater than min games (${data.played_at_least})`;
        valid = false;
      }
    } else {
      console.warn('bork', data);
    }

    setErrors(newErrors);

    return valid;
  };

  const reset = () => {
    setFilters(DefaultPlayerFilters);

    props.navigation.push('Root', { screen: 'Players', params: {} });
  };

  const submit = useCallback(() => {
    if (validate(filters)) {
      const prefix = sortDirection === 'desc' ? '-' : '';
      const sort = `${prefix}${sortField}`;

      props.navigation.push('Root', { screen: 'Players', params: {
        api: qs.stringify({ ...DefaultPlayerQuery, sort, filter: filters })
      }});
    }
  }, [ filters, sortField, sortDirection ]);

  return (
    <Center
      _dark={{ bg: "blueGray.900" }}
      _light={{ bg: "blueGray.50" }}
      flex={1}
    >
      <SafeAreaView>
        <ScrollView showsVerticalScrollIndicator={true} width={'full'}>
          <VStack p={5} space={2} alignSelf="center" w={'80%'} minW={280} maxW={420}>
            <FormControl mt={2}>
              <FormControl.Label _text={{
                bold: true
              }}>Last Played</FormControl.Label>
              <Select
                mt={1}
                selectedValue={filters?.played_after ? moment().diff(moment(filters?.played_after), 'days').toString() : ''}
                onValueChange={(val) => setFilters({ ...filters, played_after: moment().subtract(val, 'days').toDate() })}
                accessibilityLabel="Last Played"
                placeholder="Last Played"
                _selectedItem={{
                  bg: "teal.600",
                  endIcon: <CheckIcon size="5" />
                }}
              >
                <Select.Item label="1 Day" value={'1'} />
                <Select.Item label="7 Days" value={'7'} />
                <Select.Item label="30 Days" value={'30'} />
                <Select.Item label="60 Days" value={'60'} />
                <Select.Item label="90 Days" value={'90'} />
                <Select.Item label="180 Days" value={'180'} />
                <Select.Item label="360 Days" value={'360'} />
                <Select.Item label="720 Days" value={'720'} />
              </Select>
            </FormControl>
            <FormControl isInvalid={'played_at_least' in errors}>
              <FormControl.Label _text={{
                bold: true
              }}>Min Games Played</FormControl.Label>
              <KothNumberInput name='played_at_least' placeholder='10 (example)' defaultValue={filters.played_at_least} onUpdated={(val) => setFilters({ ...filters, played_at_least: val })} />
              {'played_at_least' in errors && (
                <FormControl.ErrorMessage _text={{
                  fontSize: 'xs'
                }}>
                  {errors.played_at_least}
                </FormControl.ErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={'played_at_most' in errors}>
              <FormControl.Label _text={{
                bold: true
              }}>Max Games Played</FormControl.Label>
              <KothNumberInput name='played_at_most' placeholder='1000 (example)' defaultValue={filters.played_at_most} onUpdated={(val) => setFilters({ ...filters, played_at_most: val })} />
              {'played_at_most' in errors && (
                <FormControl.ErrorMessage _text={{
                  fontSize: 'xs'
                }}>
                  {errors.played_at_most}
                </FormControl.ErrorMessage>
              )}
            </FormControl>
            <FormControl>
              <FormControl.Label _text={{
                bold: true
              }}>Sort Players By</FormControl.Label>
                <Select onValueChange={(val) => setSortField(val as PlayerSortField)} selectedValue={sortField} accessibilityLabel="Sort Players By" placeholder="Sort By" _selectedItem={{
                  bg: "teal.600",
                  endIcon: <CheckIcon size="5" />
                }} mt={1}>
                <Select.Item label="Total Score" value={'total_score'} />
                <Select.Item label="Total Games" value={'num_games'} />
                <Select.Item label="Total King" value={'total_king'} />
                <Select.Item label="Total Flags" value={'total_flags'} />
                <Select.Item label="Average Rank" value={'avg_rank'} />
                <Select.Item label="Average King" value={'avg_king'} />
                <Select.Item label="Average Flags" value={'avg_flags'} />
                </Select>
            </FormControl>
            <FormControl>
              <FormControl.Label _text={{
                bold: true
              }}>Sort Direction</FormControl.Label>
              <Select minW='35%' selectedValue={sortDirection} accessibilityLabel="Sort Direction" placeholder="Sort Direction" _selectedItem={{
                bg: "teal.600",
                endIcon: <CheckIcon size="5" />
              }} mt={1} onValueChange={itemValue => setSortDirection(itemValue as ApiSortDirection)}>
                <Select.Item label="Ascending" value={'asc'} />
                <Select.Item label="Descending" value={'desc'} />
              </Select>
            </FormControl>
            <Button.Group mt={5} space={2}>
              <Button colorScheme="danger" onPress={() => {
                reset();
              }}>
                Reset
              </Button>
              <Button onPress={() => {
                submit();
              }}>
                Search
              </Button>
            </Button.Group>
          </VStack>
        </ScrollView>
      </SafeAreaView>
    </Center>
  );
}

export default SearchPlayersScreen;
